import * as React from 'react'
import { Link, navigate } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { Auth0Provider } from '@auth0/auth0-react'

// Utils
import { client, repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import '@fontsource/figtree'
import './src/styles/default.css'

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/dashboard/', { replace: true })
}

export const wrapRootElement = ({ element }) => (
  <Auth0Provider domain={process.env.GATSBY_AUTH0_DOMAIN} clientId={process.env.GATSBY_AUTH0_CLIENTID} redirectUri={window.location.origin} onRedirectCallback={onRedirectCallback}>
    <PrismicProvider
      client={client}
      linkResolver={linkResolver}
      externalLinkComponent={(props) => {
        const childProps = { ...props }
        delete childProps.activeClassName
        childProps.target = props.target || '_blank'
        childProps.rel = props.rel || 'noopener noreferrer'
        return (
          <a data-location='external' {...childProps}>
            {props.children}
          </a>
        )
      }}
      internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}>
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
    </PrismicProvider>
  </Auth0Provider>
)
