import React from 'react'
import * as prismic from '@prismicio/client'
import { linkResolver } from './linkResolver'

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_MAIN_REPOSITORY_NAME,
    linkResolver,
    componentResolver: {
      blogpost: React.lazy(() => import('../templates/blogpost')),
      page: React.lazy(() => import('../templates/page'))
    }
  }
]

export const client = prismic.createClient(process.env.GATSBY_PRISMIC_MAIN_REPOSITORY_NAME, {
  accessToken: process.env.GATSBY_PRISMIC_ACCESS_TOKEN
})
